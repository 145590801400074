import { useEffect, useRef } from "react";

import { useAppStateContext } from "./AppStateContext";

export const PendoInitializer = () => {
  const [{ configuration }] = useAppStateContext();
  const vesselName = configuration?.vessel.name;
  const vesselContactEmail = configuration?.vessel.contactInformation.email;
  const vesselContactName = configuration?.vessel.contactInformation.name || vesselName;
  const fleetId = configuration?.fleet.id;
  const fleetName = configuration?.fleet.name;

  const pendoInitialized = useRef<boolean>(false);
  const isPendoInitialized = pendoInitialized.current === true;

  useEffect(() => {
    if (
      isPendoInitialized ||
      !vesselContactEmail ||
      !vesselContactName ||
      !fleetId ||
      !fleetName ||
      !vesselName
    ) {
      return;
    }

    pendo.initialize({
      visitor: {
        id: vesselContactEmail,
        email: vesselContactEmail,
        full_name: vesselContactName,
        vessel_name: vesselName,
      },

      account: {
        id: fleetId,
        name: fleetName,
      },
    });
    pendoInitialized.current = true;
  }, [vesselContactEmail, vesselContactName, fleetId, isPendoInitialized, fleetName]);

  return null;
};
